import React, { useReducer } from 'react';
import axios from 'axios';
import TaxonomyAutocomplete from './TaxonomyAutocomplete';
import TaxonomyContext from '../context/TaxonomyContext';
import taxonomyReducer from '../reducers/taxonomyReducer';
import { addTaxonomyTerms } from '../actions/taxonomy';

const Field = (props) => {
  const [taxonomy, dispatch] = useReducer(taxonomyReducer, taxonomyReducer.initialState);

  React.useEffect(() => {
    props.sdk.window.startAutoResizer();
  });

  React.useEffect(() => {
    const { apmApiEnv } = props.sdk.parameters.instance;
    async function fetchData() {
      const terms = await axios(`${apmApiEnv}/search/terms`);

      dispatch(addTaxonomyTerms(terms.data));
    }

    fetchData();
  }, [props.sdk]);

  return (
    <div data-testid={Field.test.id}>
      <TaxonomyContext.Provider value={taxonomy}>
        {taxonomy.allIds.length ? <TaxonomyAutocomplete sdk={props.sdk} /> : 'loading'}
      </TaxonomyContext.Provider>
    </div>
  );
};

Field.test = {
  id: 'Contentful:Field'
}

export default Field;
