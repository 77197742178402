import React, { useContext, useState } from 'react';
import _ from 'lodash';
import { Autocomplete, EmptyState, Note, Flex } from '@contentful/forma-36-react-components'
import TaxonomyContext from '../../context/TaxonomyContext';
import SelectedTermsPills from '../SelectedTermsPills';

const tagSelector = (taxonomy) => {
  return taxonomy.allIds.map(id => taxonomy.byId[id]).filter(term => term.type === "tag");
};

const TaxonomyAutocomplete = ({ sdk }) => {
  const taxonomy = useContext(TaxonomyContext)
  const [ selectedTerms, setSelectedTerms] = useState([]);
  const [ query, setQuery ] = useState('');
  const [ dropdownItems, setDropdownItems ] = useState(tagSelector(taxonomy));

  React.useEffect(() => {
    if (taxonomy.allIds.length) {
      setSelectedTerms(prevState => {
        const persisted = sdk.field?.getValue().map(id => taxonomy.byId[id]);
        return persisted;
      })
    }
  }, [taxonomy, sdk.field])

  React.useEffect(() => {
    if (taxonomy.allIds.length) {
      setDropdownItems(_.differenceBy(tagSelector(taxonomy), selectedTerms, 'id'));

      const selectedTermsIds = selectedTerms.map(term => term.id);
      sdk.field?.setValue(selectedTermsIds);
    }
  }, [taxonomy, selectedTerms, sdk.field])

  /*
   * Callbacks
   */
  const handleQueryChange = _.throttle(React.useCallback((e) => {
    if (!e.length) { // When user clears the input field
      setDropdownItems(_.differenceBy(tagSelector(taxonomy), selectedTerms, 'id'))
    } else { // When user type to search
      setDropdownItems(prevState => {
        if (e.length < query.length) { // If the user deletes one character

          // Calculates the difference of the whole taxonomy by the selected terms
          const filteredItems = _.differenceBy(tagSelector(taxonomy), selectedTerms, 'id')
            // Filter values that match to what's being typed
            .filter(term => term.value.toLowerCase().includes(e.toLowerCase()));

          // Returns final result to the state update funcion
          // updating the dropdown items
          return filteredItems;
        } // When user continues to type forward

        // Calculates the difference of the whole taxonomy by the selected terms
        const results = _.differenceBy(prevState, selectedTerms, 'id')
          // Filter values that match to what's being typed
          .filter(term => term.value.toLowerCase().includes(e.toLowerCase()));

        // Returns final result to the state update funcion
        // updating the dropdown items
        return results.length ? results : [];
      });
    }

    // Updates the query (word/phrase that is being typed)
    setQuery(e);
  }, [query.length, selectedTerms, taxonomy], 300))

  const handleChange = (term) => {
    setSelectedTerms(prevState => ([
      ...prevState,
      term
    ]));

    setQuery('');
  }

  const handlePillClose = (index) => {
    setSelectedTerms(prevState => [...prevState.slice(0, index), ...prevState.slice(index + 1)]);
  }

  return (
    <Flex margin="spacingXs" flexDirection="column" justifyContent="space-between" fullHeight>
      <Autocomplete
        placeholder="Start typing to search for facets"
        items={dropdownItems}
        onQueryChange={handleQueryChange}
        onChange={handleChange}
        maxHeight={200}
        dropdownProps={{
          usePortal: false
        }}
      >
        {options => options.map(option => {
          return (<span key={option.value}>{option.value}</span>)
        })}
      </Autocomplete>
      <Flex marginTop="spacingS" flexDirection="column">
      {!selectedTerms.length ?
       <EmptyState
         headingProps={{ text: 'Taxonomy' }}
         descriptionProps={{ text: 'Select terms from the dropdown above' }}
       />
        :
        <Flex flexDirection="row" flexWrap="wrap">
          {selectedTerms.map((term, index) => (
            <SelectedTermsPills key={term.value} term={term} onClose={() => handlePillClose(index)}/>
          ))}
        </Flex>
      }
      </Flex>
      <Flex margin="spacing4Xl" /> {/* Hack for dropdown to have some clearance on the screen */}
    </Flex>
  );
}

export default TaxonomyAutocomplete;
