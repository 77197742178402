import _ from 'lodash';
import { ADD_TAXONOMY_TERMS } from '../contants/actions';

const initialState = {
  allIds: [],
  byId: {},
};

function taxonomyReducer(state, action) {
  switch(action.type) {
      case ADD_TAXONOMY_TERMS:
        return {
          allIds: [...state.allIds, ...action.payload.filter(term => !state.allIds.includes(term.id)).map(term => term.id)],
          byId: {
            ...state.byId,
            ..._.keyBy(action.payload, 'id'),
          }
        };

      default:
        throw new Error('Taxonomy Reducer: Action not found');
  }
}

taxonomyReducer.initialState = initialState

export default taxonomyReducer;
