import React from 'react';
import { Pill, Flex } from '@contentful/forma-36-react-components';

const SelectedTermsPills = ({ onClose, term }) => {
  return (
    <Flex margin="spacingXs" htmlTag="li">
      <Pill label={term.value} onClose={() => onClose()}/>
    </Flex>
  )
};

export default SelectedTermsPills;
